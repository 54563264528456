import React, { useCallback, useState, type FC } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import links from 'links'
import { track } from 'analytics'
import { useVisibilityTrigger } from 'intersection-observer'
import { constants } from 'helpers'
import { Text, Video } from 'components/dataDisplay'

import { AspectContainer } from 'components/layout'
import { Image } from 'components/dataDisplay'
import { Button } from 'components/inputs'
import backgroundImage from './images/background.png?src-loader'
import previewImage from './images/video-preview.png?src-loader'

import messages from './messages'


type InfluencerVideoModuleProps = {
  className?: string
}

const InfluencerVideoModule: FC<InfluencerVideoModuleProps> = ({ className }) => {
  const { isMobile } = useDevice()

  const aspect = 358 / 656

  // TODO: Delete after CRO-613 ab test is over
  const socialVideoRef = useVisibilityTrigger(() => {
    track('Social video display')
  })

  const handleQuizButtonClick = useCallback(() => {
    track('Banner click', {
      action: 'link',
      link: links.smartRecommendations,
      content: 'Quiz',
      placement: 'Social video',
    })
  }, [])

  if (isMobile) {
    return (
      <div className={cx(className, 'flex flex-col justify-center')} ref={socialVideoRef}>
        <Text className="mb-4" align="center" message={messages.customersCountInfo} style="eye2" color="gold-70" />
        <Text className="mb-24" align="center" message={messages.title} style="h5" color="black" html />
        <div className="relative mx-auto flex w-full min-w-[320rem] flex-col px-[28rem]">
          <div className="absolute left-0 top-0 flex size-full cursor-pointer items-end">
            <Button
              className="z-10 mx-auto mb-24 w-[calc(100%-96rem)]"
              to={links.smartRecommendations}
              title={messages.takeQuiz}
              size={56}
              onClick={handleQuizButtonClick}
              style="gold-30"
            />
          </div>
          <Video
            className="size-full bg-transparent"
            type="youtube"
            url={constants.externalLinks.influencersSocialVideo}
            preview={previewImage.src}
            aspect={aspect}
            previewStyle="default"
            previewIconSize={60}
          />
        </div>
      </div>
    )
  }


  return (
    <div className={cx(className, 'mx-auto flex h-[636rem] items-center justify-center')} ref={socialVideoRef}>
      <div className="relative h-full w-[358rem]">
        <Video
          className={cx('absolute left-0 top-0 size-full bg-transparent')}
          type="youtube"
          url={constants.externalLinks.influencersSocialVideo}
          preview={previewImage.src}
          aspect={aspect}
          previewStyle="default"
          previewIconSize={60}
        />
      </div>
      <AspectContainer className="relative h-full w-[432rem]" aspect={backgroundImage.aspect}>
        <>
          <Image src={backgroundImage.src} alt="Bottles" fill className="object-cover" lazy />
          <div className="absolute inset-0 flex flex-col items-center justify-center p-8 text-center">
            <Text className="mb-4" message={messages.customersCountInfo} style="eye2" color="white" />
            <Text className="mb-24" message={messages.title} style="h5" color="black" html />
            <Button
              className={cx('mt-24', !isMobile && 'max-w-[320rem]')}
              title={messages.takeQuiz}
              to={links.smartRecommendations}
              size={56}
              onClick={handleQuizButtonClick}
              fullWidth
            />
          </div>
        </>
      </AspectContainer>
    </div>
  )
}


export default InfluencerVideoModule
