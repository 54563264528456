import React, { type FC } from 'react'
import cx from 'classnames'
import { useIntl } from 'intl'

import { useDevice } from 'device'
import { ScrollableContainer, WidthContainer } from 'components/layout'
import { Image, Text } from 'components/dataDisplay'
import glamourImage from './../images/glamour-white.svg?src-loader'
import vogueImage from './../images/vogue-white.svg?src-loader'
import messages from './messages'


const quoteBlocks = [
  {
    authorImage: {
      ...vogueImage,
      alt: 'Vogue',
      lazy: true,
    },
    subTitle: messages.vogueSubtitle,
    text: messages.vogueFeaturedQuote,
  },
  {
    authorImage: {
      ...glamourImage,
      alt: 'Glamour',
      lazy: true,
    },
    text: messages.glamourFeaturedQuote,
  },
]


const QuoteBlockAb: FC = () => {
  const { isMobile } = useDevice()
  const intl = useIntl()

  const slidesNodes = quoteBlocks.map(({ authorImage, text, subTitle }, index) => (
    <div
      key={index}
      className={cx('bg-dark-brown flex flex-col items-center rounded-[10rem] py-32', isMobile ? 'w-[382rem] px-16' : 'w-[552rem] px-24')}
    >
      <Image className={Boolean(subTitle) ? 'mb-24' : 'mb-32'} src={authorImage} alt={authorImage?.alt} />
      <div className="mx-auto text-center">
        {Boolean(subTitle) && (
          <Text className="mb-[28rem]" message={subTitle} style={isMobile ? 'eye1' : 'sh4'} color="light-beige" />
        )}
        <Text className="mx-auto" message={`“${intl.formatMessage(text).trim()}”`} style={isMobile ? 'p1' : 'sh3'} color="light-beige" />
      </div>
    </div>
  ))

  return (
    <WidthContainer className="mt-32 flex justify-between gap-x-32">
      {
        isMobile ? (
          <ScrollableContainer className="flex snap-x snap-mandatory space-x-12 overflow-auto [&>*]:flex-none">
            {slidesNodes}
          </ScrollableContainer>
        ) : (
          slidesNodes
        )
      }
    </WidthContainer>
  )
}

export default QuoteBlockAb
