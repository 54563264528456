import React from 'react'
import { useScrollingFrame } from 'hooks'
import { useEntry } from 'intersection-observer'
import { useDevice } from 'device'
import { cx } from 'helpers'

import { WidthContainer } from 'components/layout'
import { Image, Text } from 'components/dataDisplay'

import messages from './messages'

import brandsImage from './util/images/brands.png?src-loader'


type BrandsRowProps = {
  className?: string
  style?: 'white' | 'black'
}

const BrandsRow: React.FunctionComponent<BrandsRowProps> = (props) => {
  const { className, style } = props

  const { isMobile } = useDevice()
  const isBlackStyle = style === 'black'

  if (isMobile) {
    return (
      <MobileView className={className} style={style} />
    )
  }

  return (
    <div className={className}>
      <WidthContainer className={cx('flex justify-center py-32', isBlackStyle && 'bg-black')} size={1440}>
        <Image
          className={cx('w-[1136rem]', isBlackStyle && 'invert')}
          src={brandsImage}
          width={1136}
          alt="Brands"
        />
      </WidthContainer>
      <Text className={cx('text-center', isBlackStyle ? 'mt-24' : 'mt-8')} message={messages.text} style="p1" />
    </div>
  )
}

const MobileView: React.FunctionComponent<BrandsRowProps> = (props) => {
  const { className, style } = props

  const [ ref, entry ] = useEntry()
  const isVisible = entry?.isIntersecting

  const imageRef = useScrollingFrame({
    isVisible,
  })

  const isBlackStyle = style === 'black'

  return (
    <div ref={ref} className={cx(className, '')}>
      <div className={cx('relative flex items-center overflow-hidden py-32', isBlackStyle && 'bg-black')}>
        <div ref={imageRef} className="absolute left-0 mx-16 w-[1116rem]">
          <Image
            className={cx('w-full', isBlackStyle && 'invert')}
            src={brandsImage}
            width={1116}
            alt="Brands"
          />
        </div>
      </div>
      <Text className={cx('text-center', isBlackStyle ? 'mt-24' : 'mt-8')} message={messages.text} style="p3" />
    </div>
  )
}


export default BrandsRow
