import type { FC } from 'react'
import { cx } from 'helpers'
import { Image } from 'components/dataDisplay'
import trustPilotStar from './../images/trustpilot-star.svg?src-loader'


type TrustpilotRatingProps = {
  count?: number,
  className?: string,
  width?: number
}

const TrustpilotRating: FC<TrustpilotRatingProps> = ({ count = 4, className, width }) => (
  <div className={cx(className, 'flex justify-center gap-x-2')}>
    {Array.from({ length: count }).map((_, i) => (
      <Image key={i} src={trustPilotStar} alt="Trustpilot Star" remWidth={width} width={width} height={width} />
    ))}
  </div>
)

export default TrustpilotRating
