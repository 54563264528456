import React from 'react'
import links from 'links'
import { useDevice } from 'device'
import { track } from 'analytics'

import { Button, type ButtonProps } from 'components/inputs'

import { useLandingOnGetStartedClick, useLandingTrackParams } from 'containers/LandingContextProvider/LandingContextProvider'
import SubscribeButton from 'compositions/buttons/SubscribeButton/SubscribeButton'

import messages from './messages'


export type GetStartedButtonProps = Pick<ButtonProps, 'width' | 'fullWidth' | 'fullWidthOnMobile'> & {
  className?: string
  actionType?: 'quiz' | 'subscribe' | 'link'
  size?: Extract<ButtonProps['size'], 38 | 56>
  style?: Extract<ButtonProps['style'], 'primary' | 'gold-30'>
  inlineIcon?: ButtonProps['inlineIcon']
  placement: string
  title?: Intl.Message | string
  to?: string
}

// one universal button for landings
const GetStartedButton: React.FunctionComponent<GetStartedButtonProps> = (props) => {
  const {
    className,
    actionType = 'quiz',
    size = 56,
    style = 'primary',
    width,
    fullWidth,
    fullWidthOnMobile,
    placement,
    title: customTitle,
    to: customTo,
  } = props

  const { isMobile } = useDevice()

  const trackParams = useLandingTrackParams()
  const onGetStartedClick = useLandingOnGetStartedClick({ placement })

  const handleClick = (event) => {
    if (typeof onGetStartedClick === 'function') {
      void onGetStartedClick()
    }
    if (typeof buttonProps.onClick === 'function') {
      buttonProps.onClick(event)
    }
  }

  const buttonProps: ButtonProps = {
    className,
    size,
    style,
    width,
    fullWidth,
    html: true,
  }

  if (actionType === 'subscribe') {
    return (
      <SubscribeButton
        {...buttonProps}
        placement={placement}
        title={customTitle}
        trackParams={trackParams}
        onClick={handleClick}
      />
    )
  }

  const to = customTo ?? links.smartRecommendations
  const isQuiz = to === links.smartRecommendations

  return (
    <Button
      {...buttonProps}
      to={to}
      title={customTitle || messages.quiz}
      inlineIcon={props.inlineIcon ?? (isQuiz && isMobile ? '32/arrow-right' : null)}
      style={style}
      width={width}
      fullWidth={fullWidth}
      fullWidthOnMobile={fullWidthOnMobile}
      html
      onClick={(event) => {
        track('Banner click', {
          ...trackParams,
          placement,
          content: isQuiz ? 'Quiz' : 'Link',
          link: to,
          action: 'link',
        })

        handleClick(event)
      }}
      data-testid="getStartedButton"
    />
  )
}


export default GetStartedButton
