export default {
  default: {
    noDiscount: {
      title: {
        en: `
        Over {fragrancesAmount}<br/> designer scents,<br/> <span class="{highlightTextClassName}">{price} a month</span>
        `,
      },
      text: {
        en: 'Skip the department store. We\'ll deliver new designer scents to you every month.',
      },
    },
    discount: {
      title: {
        en: `
        Over {fragrancesAmount}<br class="onlyMobile"/> designer scents,<br class="onlyMobile"/>
         <s>{price}</s><br class="onlyDesktop"/> <span class="{highlightTextClassName}">{priceWithDiscount} a month</span>
        `,
      },
      text: {
        en: 'Skip the department store. We\'ll deliver new designer scents to you every month.',
      },
    },
    freeTrial: {
      title: {
        en: `Over {fragrancesAmount}<br/> designer scents,<br/>
        <span class="{highlightTextClassName} whitespace-nowrap">claim your<br class="onlyDesktop"/> free&nbsp;trial</span>`,
      },
      text: {
        en: `
       Skip the department store and have designer scents delivered directly to you.<br/><br/>
       Your first month is free, just pay<br class="onlyDesktop"/>  {extraShippingPrice} for shipping and handling.<br class="onlyDesktop"/> Your second month will be {price}.
        `,
      },
    },
    freeProduct: {
      title: {
        en: `
        Over {fragrancesAmount}<br class="onlyDesktop"/> designer<br class="onlyMobile"/> scents,
        <br class="onlyDesktop"/> <span class="{highlightTextClassName}">claim your<br class="onlyMobile"/> free perfume</span>
        `,
      },
      text: {
        en: 'Skip the department store and have designer scents delivered directly to you for {price} a month—we\'ll include a free scent in your first month\'s order.',
      },
    },
    freeMonth: {
      title: {
        en: `
        Over {fragrancesAmount}<br class="onlyDesktop"/> designer<br class="onlyMobile"/> scents,
        <br class="onlyDesktop"/> <span class="{highlightTextClassName}">get your<br class="onlyMobile"/> second month free</span>
        `,
      },
      text: {
        en: 'Skip the department store and have designer scents delivered directly to you—your first month will be {price}, your second month is on us.',
      },
    },
  },
}
