export default {
  title: {
    en: 'A fragrance subscription you can trust',
  },
  features: {
    fragrances: {
      digits: {
        en: '900+',
      },
      title: {
        en: 'fragrances',
      },
      subtitle: {
        en: 'from designers you\'ll love',
      },
    },
    brands: {
      digits: {
        en: '200+',
      },
      title: {
        en: 'brands',
      },
      subtitle: {
        en: 'luxury & niche curated for you',
      },
    },
    customers: {
      digits: {
        en: '1,000,000+',
      },
      title: {
        en: 'customers',
      },
      subtitle: {
        en: 'satisfied members of our community',
      },
    },
  },
}
