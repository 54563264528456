import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import { type ImageProps, Image, Text } from 'components/dataDisplay'

import messages from './messages'

import gqImage from './images/gq.svg?src-loader'
import esquireImage from './images/esquire.svg?src-loader'
import buzzFeedImage from './images/buzzfeed.svg?src-loader'
import forbesImage from './images/forbes.svg?src-loader'
import QuoteBlockAb from './components/QuoteBlockAb'


const otherPressImages: ImageProps[] = [
  {
    className: 'w-[60rem]',
    src: gqImage,
    alt: 'GQ',
  },
  {
    className: 'w-[120rem]',
    src: esquireImage,
    alt: 'Esquire',
  },
  {
    className: 'w-[100rem]',
    src: buzzFeedImage,
    alt: 'BuzzFeed',
  },
  {
    className: 'w-[100rem]',
    src: forbesImage,
    alt: 'Forbed',
  },
]

type PressSectionProps = {
  className?: string
}

const PressSectionAb: React.FunctionComponent<PressSectionProps> = ({ className }) => {
  const { isMobile } = useDevice()

  return (
    <>
      <Text
        className={className}
        message={messages.asFeatured}
        style="h6"
        color="gold-50"
        align="center"
      />
      <QuoteBlockAb />
      <div className={cx('mx-auto flex max-w-[548px] gap-y-40', isMobile ? 'mt-24 flex-wrap justify-center gap-x-32 px-20' : 'mt-32 justify-between')}>
        {
          otherPressImages.map((imageProps) => (
            <Image
              key={imageProps.alt}
              remWidth={120}
              {...imageProps}
            />
          ))
        }
      </div>
    </>
  )
}


export default PressSectionAb
