/* eslint-disable stylistic/max-len */
export default {
  trustPilotTitle: {
    en: 'From trustpilot',
  },
  title: {
    en: `HERE'S WHY OUR <br/> CUSTOMERS LOVE US`,
  },
  ratingTitle: {
    en: 'Very Good',
  },
  reviewCount: {
    en: 'Based on <b>5000+ reviews</b>',
  },
  verified: {
    en: 'Verified',
  },
  slides: [
    {
      title: {
        en: 'I love the options of different scents',
      },
      text: {
        en: `I love the options of different scents. This has opened my eyes to a whole new world of fragrance`,
      },
      address: {
        en: 'Shawn carr, United States',
      },
    },
    {
      title: {
        en: 'Definitely worth it!',
      },
      text: {
        en: 'Affordable, great options, easy delivery, and a good way to explore scents you are unsure of without the commitment of a full bottle.',
      },
      address: {
        en: 'Hayli, United States',
      },
    },
    {
      title: {
        en: 'Two months into Scentbirds monthly…',
      },
      text: {
        en: `Two months into Scentbirds monthly subscription service and very happy with my first two selections. Excitedly waiting for this month's choice to arrive. Prompt delivery and excellent packaging.`,
      },
      address: {
        en: 'Wanda C, United States',
      },
    },
    {
      title: {
        en: 'Great prices',
      },
      text: {
        en: `Great prices, authentic delicious scents, Scentbird makes it so easy to choose your monthly scents,
             im sticking with Scentbird for a while! I'm done wearing 5 minute body sprays.`,
      },
      address: {
        en: 'Lou Gonzalez, United States',
      },
    },
  ],
}

