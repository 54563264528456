import React from 'react'
import { useDevice } from 'device'
import { cx } from 'helpers'

import { Carousel, Image } from 'components/dataDisplay'

import desktopSlide1Image from './images/desktop-1.jpg'
import desktopSlide2Image from './images/desktop-2.jpg'
import desktopSlide3Image from './images/desktop-3.jpg'
import desktopSlide4Image from './images/desktop-4.jpg'
import desktopSlide5Image from './images/desktop-5.jpg'
import mobileSlide1Image from './images/mobile-1.jpg'
import mobileSlide2Image from './images/mobile-2.jpg'
import mobileSlide3Image from './images/mobile-3.jpg'
import mobileSlide4Image from './images/mobile-4.jpg'
import mobileSlide5Image from './images/mobile-5.jpg'


const slidesByDevice = {
  mobile: [ mobileSlide1Image, mobileSlide2Image, mobileSlide3Image, mobileSlide4Image, mobileSlide5Image ],
  desktop: [ desktopSlide1Image, desktopSlide2Image, desktopSlide3Image, desktopSlide4Image, desktopSlide5Image ],
}

const Slider: React.FunctionComponent = () => {
  const { isMobile } = useDevice()
  const slides = slidesByDevice[isMobile ? 'mobile' : 'desktop']

  return (
    <Carousel
      className={isMobile ? '-mx-16' : 'absolute right-0 top-0 w-[811rem]'}
      containerClassName={isMobile ? '' : 'flex flex-col h-[768rem]'}
      autoplay
      loop
      slidesToScroll={1}
      skipSnaps={false}
      axis={isMobile ? 'x' : 'y'}
      dots={{
        className: cx('absolute left-1/2 z-1 !min-h-fit -translate-x-1/2', isMobile ? '-bottom-4' : 'bottom-40'),
        dotClassName: 'mt-0 mb-0 bg-white border-solid-gold-50',
        activeDotClassName: 'bg-gold-50',
      }}
      withButtons={false}
      withDots
    >
      {
        slides.map((slide) => (
          <div>
            <Image
              className="w-full"
              src={slide}
              alt="Slide with fragrance"
              sizes={isMobile ? '100vw' : '811px'}
            />
          </div>
        ))
      }
    </Carousel>
  )
}


export default React.memo(Slider)
