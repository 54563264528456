import React, { type FC } from 'react'
import { Divider } from 'components/layout'
import { Text, Image } from 'components/dataDisplay'
import grayTick from './../images/gray-tick.svg?src-loader'

import messages from './../messages'
import TrustpilotRating from './TrustpilotRating'


type TrustpilotReviewCardProps = {
  title?: Intl.Message,
  text?: Intl.Message,
  address?: Intl.Message
}

const TrustpilotReviewCard: FC<TrustpilotReviewCardProps> = ({ title, text, address }) => (
  <div className="flex min-w-[320rem] flex-col justify-start gap-y-8 bg-white p-20  sm:min-w-[384rem]">
    <div className="flex w-full justify-between">
      <TrustpilotRating count={5} width={20} />
      <div className="flex gap-x-4">
        <Image src={grayTick} alt="Tick" />
        <Text className="flex items-center" message={messages.verified} style="p5" color="gray-80" />
      </div>
    </div>
    <Text className="font-bold" message={title} align="left" style="p1" color="black" />
    <Text message={text} style="p4" color="black" />
    <Divider className="w-60" />
    <Text message={address} align="left" style="p4" color="black" />
  </div>
)


export default TrustpilotReviewCard
