export default {
  customersCountInfo: {
    en: 'OVER 750,000 CUSTOMERS LOVE US',
  },
  title: {
    en: 'SEE WHAT THE <br/> HYPE IS ABOUT',
  },
  takeQuiz: {
    en: 'Take the quiz now',
  },
}
