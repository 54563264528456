import React, { type FC } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { ScrollableContainer, WidthContainer } from 'components/layout'
import { Text, Image } from 'components/dataDisplay'
import trustPilotLogo from './images/trustpilot-logo.svg?src-loader'

import TrustpilotRating from './components/TrustpilotRating'
import TrustpilotReviewCard from './components/TrustpilotReviewCard'

import messages from './messages'


type TrustPilotSectionProps = {
  className?: string
}

const TrustPilotSection: FC<TrustPilotSectionProps> = ({ className }) => {
  const { isMobile } = useDevice()

  const commonInfoBlock = (
    <>
      <Text className="font-bold" message={messages.ratingTitle} style="p1" color="black" />
      <TrustpilotRating className="gap-x-8" />
      <Text message={messages.reviewCount} style="p4" color="black" html />
      <Image src={trustPilotLogo} alt="Trustpilot logo" />
    </>
  )

  return (
    <WidthContainer className={cx(className, 'flex flex-col justify-center bg-light-beige py-48', isMobile ? 'px-16' : 'pl-24')} size={1440}>
      <Text className="mb-4" align="center" message={messages.trustPilotTitle} style="eye2" color="gold-50" />
      <Text className="mb-24" align="center" message={messages.title} style="h5" color="black" html />
      {isMobile ? (
        <>
          <div className="flex max-h-[236rem] w-full min-w-[320rem] flex-col items-center justify-center gap-y-8 bg-white p-20">
            {commonInfoBlock}
          </div>
          <ScrollableContainer className="mt-24 flex snap-x snap-mandatory flex-row gap-x-20">
            {messages.slides.map((slide, index) => (
              <TrustpilotReviewCard key={index} {...slide} />
            ))}
          </ScrollableContainer>
        </>
      ) : (
        <ScrollableContainer className="mt-24 flex snap-x snap-mandatory flex-row gap-x-24" isDesktopEnabled>
          <div className="flex max-h-[236rem] w-full min-w-[368rem] flex-col items-center justify-center gap-y-8 bg-white px-20 py-48">
            {commonInfoBlock}
          </div>
          <div className="flex gap-x-24 last:pr-24">
            {messages.slides.map((slide, index) => (
              <TrustpilotReviewCard key={index} {...slide} />
            ))}
          </div>
        </ScrollableContainer>
      )}
    </WidthContainer>
  )
}

export default TrustPilotSection
