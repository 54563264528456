import React from 'react'
import { useDevice } from 'device'
import { cx } from 'helpers'
import links from 'links'
import { track } from 'analytics'
import { useUser } from 'modules/user'

import { HeroMediaBlock, Text, Image, Carousel } from 'components/dataDisplay'
import { ScrollableContainer, WidthContainer } from 'components/layout'
import { Button } from 'components/inputs'
import { Href } from 'components/navigation'

import addictiveDesktopImage from './images/desktop/addictive.jpg'
import sweetDesktopImage from './images/desktop/sweet.jpg'
import freshDesktopImage from './images/desktop/fresh.jpg'
import boldDesktopImage from './images/desktop/bold.jpg'
import othersDesktopImage from './images/desktop/others.jpg'

import addictiveMobileImage from './images/mobile/addictive.jpg'
import sweetMobileImage from './images/mobile/sweet.jpg'
import freshMobileImage from './images/mobile/fresh.jpg'
import boldMobileImage from './images/mobile/bold.jpg'
import othersMobileImage from './images/mobile/others.jpg'

import notesImage from './images/notes.png'

import messages from './messages'


const carouselImages = [
  {
    desktop: addictiveDesktopImage,
    mobile: addictiveMobileImage,
  },
  {
    desktop: sweetDesktopImage,
    mobile: sweetMobileImage,
  },
  {
    desktop: freshDesktopImage,
    mobile: freshMobileImage,
  },
  {
    desktop: boldDesktopImage,
    mobile: boldMobileImage,
  },
  {
    desktop: othersDesktopImage,
    mobile: othersMobileImage,
  },
]

type QuizSectionProps = {
  className?: string
}

const QuizSection: React.FC<QuizSectionProps> = ({ className }) => {
  const { isMobile } = useDevice()

  if (isMobile) {
    return (
      <div className={cx(className, 'bg-light-beige pb-24 pt-48')}>
        <WidthContainer>
          <ButtonsBlock />
          <ScrollableContainer className="mt-24 snap-x snap-mandatory">
            <div className="inline-grid auto-cols-[326rem] grid-flow-col gap-16">
              {messages.slides.map((message, index, slidesArray) => {
                const isLastSlide = index === slidesArray.length - 1

                return (
                  <Href to={links.smartRecommendations} key={index}>
                    <figure className="relative">
                      <Image className="min-h-[413rem] min-w-[326rem]" src={carouselImages[index]?.mobile} sizes="326px" alt={message.en} />
                      <figcaption className="absolute bottom-24 left-16">
                        {!isLastSlide && (
                          <Text message={messages.scentType} style="eye1" color="white" />
                        )}
                        <Text
                          className="mt-4"
                          message={message}
                          style="h4"
                          color={isLastSlide ? 'black' : 'white'}
                          html
                        />
                      </figcaption>
                    </figure>
                  </Href>
                )
              })}
            </div>
          </ScrollableContainer>
        </WidthContainer>
      </div>
    )
  }

  return (
    <div>
      <HeroMediaBlock className={className} wideMT={false} wideMB={false}>
        <Carousel
          loop
          autoplay
          autoplaySpeed={5000}
          withDots
          dots={{
            className: 'absolute inset-x-0 bottom-0',
            dotClassName: 'bg-white desktop-hover:bg-gold-50',
            activeDotClassName: 'bg-gold-50',
          }}
        >
          {messages.slides.map((message, index, slidesArray) => {
            const isLastSlide = index === slidesArray.length - 1

            return (
              <div className="relative" key={index}>
                <HeroMediaBlock.Image src={carouselImages[index]?.desktop} height={688} alt={message.en} />
                <div className="bg-light-beige absolute right-0 top-1/2 w-[712rem] -translate-y-1/2">
                  <ButtonsBlock title={isLastSlide ? message : undefined} />
                </div>
                {!isLastSlide && (
                  <figcaption className="absolute bottom-56 left-48 max-w-[636rem]">
                    <Text message={messages.scentType} style="h3" color="white" />
                    <Text className="mt-4" message={message} style="h1" color="white" html />
                  </figcaption>
                )}
              </div>
            )
          })}
        </Carousel>
      </HeroMediaBlock>
    </div>
  )
}

type ButtonsBlockProps = {
  title?: Intl.Message
}

const ButtonsBlock: React.FC<ButtonsBlockProps> = ({ title }) => {
  const { isMobile } = useDevice()
  const { user } = useUser()

  const hasScentProfile = Boolean(user?.analyticsMetadata?.hasScentProfile)

  return (
    <div className={cx('flex flex-col', isMobile ? 'items-center text-center' : 'p-72')}>
      <Text message={title || messages.title} style={isMobile ? 'h4' : 'h3'} html />
      <Image className="mt-16 max-w-[275rem]" src={notesImage} sizes="275px" alt="Perfume Notes" />
      <Text className="mt-16" message={messages.subtitle} style={isMobile ? 'p2' : 'p1'} html />
      <Button
        className={cx('mt-24', !isMobile && 'max-w-[320rem]')}
        title={hasScentProfile ? messages.retakeQuiz : messages.takeQuiz}
        to={links.smartRecommendations}
        size={56}
        fullWidthOnMobile
        onClick={() =>
          track('Banner click', {
            placement: 'Build your scent profile',
            content: 'Quiz',
            link: links.smartRecommendations,
            action: 'link',
          })}
        data-testid="takeQuiz"
      />
    </div>
  )
}

export default QuizSection
