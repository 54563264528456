import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { usePrefersReducedMotion } from 'hooks'

import { Text, Video } from 'components/dataDisplay'
import { WidthContainer } from 'components/layout'

import GetStartedButton from '../GetStartedButton/GetStartedButton'

import fragrancesMobileVideo from './images/mobile/fragrances.mp4'
import brandsMobileVideo from './images/mobile/brands.mp4'
import customersMobileVideo from './images/mobile/customers.mp4'

import fragrancesDesktopVideo from './images/desktop/fragrances.mp4'
import brandsDesktopVideo from './images/desktop/brands.mp4'
import customersDesktopVideo from './images/desktop/customers.mp4'


import messages from './messages'


const features = [
  {
    videoUrl: {
      mobile: fragrancesMobileVideo,
      desktop: fragrancesDesktopVideo,
    },
    messages: messages.features.fragrances,
  },
  {
    videoUrl: {
      mobile: brandsMobileVideo,
      desktop: brandsDesktopVideo,
    },
    messages: messages.features.brands,
  },
  {
    videoUrl: {
      mobile: customersMobileVideo,
      desktop: customersDesktopVideo,
    },
    messages: messages.features.customers,
  },
]


type SocialProofSectionProps = {
  className?: string
  startButtonMessage?: string | Intl.Message
}

const SocialProofSection: React.FunctionComponent<SocialProofSectionProps> = (props) => {
  const { className, startButtonMessage } = props

  const { isMobile } = useDevice()

  return (
    <WidthContainer className={cx(className, 'flex flex-col items-center')}>
      <Text
        className={cx('text-center', isMobile ? 'max-w-372' : 'max-w-464')}
        message={messages.title}
        style={isMobile ? 'h5' : 'h4'}
      />
      <div className={cx('mt-32 gap-32', isMobile ? 'flex flex-col' : 'grid grid-cols-3')}>
        {
          features.map((feature, index) => (
            <FeatureItem
              key={index}
              videoUrl={feature.videoUrl[isMobile ? 'mobile' : 'desktop']}
              messages={feature.messages}
            />
          ))
        }
      </div>
      <GetStartedButton
        className="mt-32"
        title={startButtonMessage}
        width={320}
        fullWidthOnMobile
        placement="Social proof"
      />
    </WidthContainer>
  )
}

type FeatureItemProps = {
  className?: string
  videoUrl: string
  messages: {
    digits: string | Intl.Message
    title: string | Intl.Message
    subtitle: string | Intl.Message
  }
}

const FeatureItem: React.FunctionComponent<FeatureItemProps> = (props) => {
  const { className, videoUrl, messages } = props

  const { isMobile } = useDevice()
  const prefersReducedMotion = usePrefersReducedMotion()


  return (
    <div className={cx(className, 'flex items-center gap-24', !isMobile && 'flex-col')}>
      <Video
        className={cx(isMobile ? 'size-[100rem] min-h-[100rem] min-w-[100rem]' : 'size-[200rem] min-h-[200rem] min-w-[200rem]')}
        videoClassName="bg-gray-10 rounded-full"
        url={videoUrl}
        type="mp4"
        autoplay={!prefersReducedMotion}
        muted
        loop
        aspect={1}
        withBackground={false}
      />
      <div className={cx('flex flex-col gap-8', !isMobile && 'items-center')} >
        <Text
          message={messages.digits}
          style={isMobile ? 'h3' : 'h2'}
          color="gold-70"
        />
        <Text
          message={messages.title}
          style="h7"
        />
        <Text
          message={messages.subtitle}
          style="p1"
        />
      </div>
    </div>
  )
}


export default SocialProofSection
