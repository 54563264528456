export default {
  vogueSubtitle: {
    en: 'Best Perfume Subscription Box',
  },
  vogueFeaturedQuote: {
    en: 'Scentbird offers a convenient way to explore your signature scent.',
  },
  glamourFeaturedQuote: {
    en: 'Scent-sational monthly subscription box that delivers a new perfume to your doorstep each month.',
  },
}
